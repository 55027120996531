import React from "react";
import blog1Data from "data/elf/sports.json";
import Navbar from "components/Navbar/navbar";
import BlogStanderd from "components/Blog-standerd/blog-standerd";
import SportsSectionElf from "components/Elf/Sports-sectionElf/Sports-sectionElf";
import PageHeader from "components/Page-header/page-header";
import Footer from "components/Footer/footer";
import DarkTheme from "layouts/Dark";

const Blogs = () => {
  const navbarRef = React.useRef(null);
  const logoRef = React.useRef(null);

  React.useEffect(() => {
    var navbar = navbarRef.current;
    
    if (window.pageYOffset > 300) {
      navbar.classList.add("nav-scroll");
    } else {
      navbar.classList.remove("nav-scroll");
    }

    window.addEventListener("scroll", () => {
      if (window.pageYOffset > 300) {
        navbar.classList.add("nav-scroll");
      } else {
        navbar.classList.remove("nav-scroll");
      }
    });
  }, [navbarRef]);

  return (
    
    <DarkTheme>
      <div className="circle-bg">
        <div className="circle-color fixed">
          <div className="gradient-circle"></div>
          <div className="gradient-circle two"></div>
        </div>
      </div>
      <Navbar nr={navbarRef} lr={logoRef} />
      <PageHeader
        title="Sports"
        paragraph="Sports events of our team."
      />
      <h1 className="d-none">Sports events of our team.</h1>
      <SportsSectionElf blogs={blog1Data} />
      <Footer />
    </DarkTheme>
  );
};

export const Head = () => {
  return (
    <>
      <title>Sports - Elfonze Technologies</title>
      <meta key="description" name="description"
        content="Dive into the heart of sports action with our comprehensive sports page. Get the latest news, scores, highlights, and in-depth analysis, all in one place. Whether you're a die-hard fan or a casual observer, find your game here." />
    </>
  )
}

export default Blogs;
